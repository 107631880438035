import React from "react"
import Slider from "react-slick"

// assets
import Arrow from "../images/caret.svg"

const NextArrow = props => {
  return (
    <button
      type="button"
      data-role="none"
      className={props.className}
      onClick={props.onClick}
    >
      <Arrow />
    </button>
  )
}

const PrevArrow = props => {
  return (
    <button
      type="button"
      data-role="none"
      className={props.className}
      onClick={props.onClick}
    >
      <Arrow />
    </button>
  )
}

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { render: false }
  }
  componentDidMount() {
    this.setState({ render: true })
  }
  render() {
    const { render } = this.state
    const settings = {
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <NextArrow />,
      nextArrow: <PrevArrow />,
      swipeToSlide: true,
      dots: true,
      dotsClass: "slider-dots list--reset",
      responsive: [
        //     { breakpoint: 1024, settings: { arrows: true, slidesToShow: 3 } },
        //     {
        //       breakpoint: 900,
        //       settings: {
        //         swipeToSlide: true,
        //         arrows: false,
        //         slidesToShow: 2,
        //         slidesToScroll: 1,
        //       },
        //     },
        {
          breakpoint: 768,
          settings: {
            swipeToSlide: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    if (render) {
      return <Slider {...settings}>{this.props.children}</Slider>
    }
    return null
  }
}

export default Carousel
