const partnerLogos = [
  {
    Company: "VH-1",
    Filename: "MTV_adcouncil_partner_vh1.svg",
    Filetype: "svg",
    wide: 2,
  },
  {
    Company: "MTV",
    Filename: "MTV_adcouncil_partner_mtv.svg",
    Filetype: "svg",
    wide: 2,
  },
  {
    Company: "Comedy Central",
    Filename: "MTV_adcouncil_partner_cc.svg",
    Filetype: "svg",
    wide: 2,
  },
  {
    Company: "Pluto",
    Filename: "pluto.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "Vote Early Day",
    Filename: "VED_White.png",
    Filetype: "png",
    wide: 3,
  },
  {
    Company: "ATV",
    Filename: "ATV_Logo_allwhite.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "Simon & Shuster",
    Filename: "S&S.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "CBS",
    Filename: "cbs.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "Broadway Green Alliance",
    Filename: "bga.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "Equis",
    Filename: "equis.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "Circle",
    Filename: "circle.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "More In Common",
    Filename: "MIC.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "National Black Justice Coalition",
    Filename: "nbjc-logo.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "NALEO",
    Filename: "Naleo.png",
    Filetype: "png",
    wide: 3,
  },
  {
    Company: "MAP",
    Filename: "MAP.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "FRRC",
    Filename: "FRRC.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "TuneIn",
    Filename: "TuneIn.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "Adtheorent",
    Filename: "atlogo.gif",
    Filetype: "gif",
    wide: 1,
  },

  {
    Company: "Gum Gum",
    Filename: "gumgum.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "Alliance for Youth",
    Filename: "alliance-for-youth.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "Chegg",
    Filename: "logo-Chegg.png",
    Filetype: "png",
    wide: 1,
  },
  {
    Company: "Kargo",
    Filename: "KARGO.png",
    Filetype: "png",
    wide: 2,
  },
  {
    Company: "Election Projection",
    Filename: "election-projection.png",
    Filetype: "png",
    wide: 1,
  },
]

export default partnerLogos
