import React from "react"
import "./alert-bar.scss"

const AlertBar = ({ children }) => {
  return (
    <div className="alert-bar">
      <div className="alert-bar__inner">{children}</div>
    </div>
  )
}

export default AlertBar
